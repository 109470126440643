import React from 'react';
import { Carousel, Row, Col, Button } from 'react-bootstrap';
import { Container, Card } from 'react-bootstrap';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import _ from "lodash"
import { Link } from "gatsby"
var carouselData;
const ContentfulTemplateCarousel = ({ data: { entryTitle, slides, carouselSize, gridClasses ,groupTitle ,cssClasses, cssId}, config}) => {
    var setView;
    // if (window.matchMedia("(min-width: 767px)").matches) {
        setView=true;
        carouselData=  _.chain(slides)
        .groupBy("groupTitle")
        .map((value, key) => ({ color: key, users: value }))
        .value()
    //   } else {
    //     setView=false;
    // }
    const options = {
        renderText: text => text.split('<br>').flatMap((text, i) => [i > 0 && <br />, text])
    }

    return (
        <>
            {/* <div className={gridClasses}>
                <Carousel>
                    {
                        slides.map(({ entryTitle: slideTitle, heroImage: { file: { url } }, column1 }) => {
                            return (
                                <Carousel.Item key={slideTitle}>
                                    <img className="d-block w-100" src={"https:" + url} alt="First slide" />
                                    <Carousel.Caption>
                                        {(() => {
                                            if (column1 !== null) {
                                                return (
                                                    <>{documentToReactComponents(column1.json)}</>
                                                )
                                            }
                                        })()}
                                          <Form/>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </div> */}
            <div className={gridClasses}>
           
                <Carousel className="mobile-hide">
                    {carouselData.map((groupList) => {
                        return (
                            <Carousel.Item>
                                {
                                    groupList.users.map((data) => {
                                        return (
                                            <Card style={{ width: `18rem`, ...(data.floatType ? { float: `right` } : { float: `left` }) }}>
                                                <Card.Img alt={data.heroImage.description} variant="top" src={data.heroImage.file.url} roundedCircle />
                                                <Card.Body>
                                                    <Card.Title>{data.entryTitle}</Card.Title>
                                                    {/* <Card.Subtitle className="mb-2 text-muted">{data.author}</Card.Subtitle> */}
                                                    <Card.Text>
                                                        {documentToReactComponents(data.column1.json, options)}
                                                    </Card.Text>
                                                    <Link to={config.testimonialsPageUrl + "#" + data.cssId}>Read More..</Link>
                                                </Card.Body>
                                            </Card>
                                        )
                                    })
                                }
                            </Carousel.Item>
                        )
                    })
                    }
                </Carousel>
               

                <Carousel className="desktop-hide">
                    {slides.map(( slide ) => {
                        return (
                            <Carousel.Item>
                                <Card  style={{ width: `18rem`,float:`left` }}>
                                    <Card.Img alt={slide.heroImage.description} variant="top" src={slide.heroImage.file.url} roundedCircle />
                                    <Card.Body>
                                        <Card.Title>{slide.entryTitle}</Card.Title>
                                        
                                        <Card.Text>
                                        {documentToReactComponents(slide.column1.json, options)}
                                        </Card.Text>
                                        <Link to={config.testimonialsPageUrl +"#"+slide.cssId}>Read More..</Link>
                                    </Card.Body>
                                </Card>
                            </Carousel.Item>
                          )
                        })
                    }
                    </Carousel>

                
            </div>
        </>
    );
}


export default ContentfulTemplateCarousel