import React from 'react';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Form  from "./Form"

const ContentfulTemplateExtendedParagraph = ({data: {entryTitle, body, author, title, photo, pinned,gridClasses,showCtaButton}, config}) => {

    if (typeof window !== 'undefined') {
        var redirectTo= document.URL.split('#');
        if(redirectTo[1]){
            setTimeout( function(){ 
                document.querySelector("."+redirectTo[1]).scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
                var yOffset = 0;
                if(window.screen.width > 767){
                    yOffset = -150; 
                }else{
                    yOffset = -70; 
                }
                const element = document.querySelector("."+redirectTo[1]);
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }  , 100 );
        }
    }
    var options = {
        renderText: text => text.split('<br>').flatMap((text, i) => [i > 0 && <br />, text]),
        renderNode: {
            'embedded-asset-block': (node) => {
                let file = node.data.target.fields.file
                let desc = node.data.target.fields.description
                return <img alt={desc['en'] ?  desc['en'] : desc['el']} src={file['en'] ?  file['en'].url : file['el'].url} />
            }
        }
    }
    return (
       <>
            <div className={gridClasses}>
                {photo !== null? (
                    <div className="par-img"><img alt={photo.description} src={photo.file.url} /></div>
                ) : (
                    null
                )}
                <div className="par-par">
                    {(() => {
                        if (body !== null) {
                            return (
                                <>{documentToReactComponents(body.json,options)}</>
                            )
                        }
                    })()}
                </div>
                { showCtaButton && <Form config={config} /> }
            </div>
       </>
    );
}

export default ContentfulTemplateExtendedParagraph