import React, { Component} from 'react';

const ContentfulPage = ({data: {entryTitle, pageTitle, pageDescription}}) => {
    return (
        <div>
            
        </div>
    );
}

export default ContentfulPage