import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import SiteFooterStyle from "./SiteFooter.module.scss"
import { Link } from "gatsby"
import Form from "./Form";

const Footer = ({ siteConfig }) => {

const options = {
    renderText: text => text.split('<br>').flatMap((text, i) => [i > 0 && <br />, text])
}

  return (
    <>
      <span className="desktop-hide">
        <Form config={siteConfig} isFooter="true" />
      </span>
      <footer>
        <footerinfo>
          <Container>
            <Row>
              <Col>
                <Row>
                  <Col className="text-center mb-5">
                    {documentToReactComponents(siteConfig.footerHeader.json, options)}
                  </Col>
                </Row>
                <Row>
                  <Col className={SiteFooterStyle.footerinfo2} md={6} lg={4} sm={6} xs={12}>
                    {documentToReactComponents(siteConfig.footerAddress.json, options)}
                  </Col>
                  <Col className={SiteFooterStyle.footerinfo1} md={3} lg={2} sm={6} xs={6}>
                    <div className="footer-image pt-5">
                      <Link to={siteConfig.homePageUrl}>
                        <img alt={siteConfig.footerImage1.description} src={siteConfig.footerImage1.file.url} height="60" />
                      </Link>
                    </div>
                  </Col>

                  <Col className={SiteFooterStyle.footerinfo1} md={3} lg={2} sm={6} xs={6}>
                    <div className="footer-image">
                      <a href="https://www.myhealthspecialist.com/specialists/paul-erotocritou" target="_blank">
                        <img alt={siteConfig.footerImage2.description} src={siteConfig.footerImage2.file.url} height="145" />
                      </a>
                    </div>
                  </Col>
                  <Col className={SiteFooterStyle.footerinfo2} md={6} lg={4} sm={6} xs={12}>
                    <h4>{siteConfig.footerAppointmentsHeader}</h4>
                    <p>{siteConfig.footerAppointmentsLabel1} <a href={"tel:+44" + siteConfig.contactNumber.substring(1).replace(/\s/g, '')}>{siteConfig.contactNumber}</a></p>
                    <p>{siteConfig.footerAppointmentsLabel2} {siteConfig.faxNumber}</p>
                    <p>{siteConfig.footerAppointmentsLabel3} <a href={"mailto:" + siteConfig.emailAddress} target="_top">{siteConfig.emailAddress}</a></p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </footerinfo>
        <copyright>
          <Container fluid="true">
            <Row>
              <Col>
                {siteConfig.footerCopyright}    |    <Link to={siteConfig.privacyPolicyPageUrl}>{siteConfig.footerPrivacyPolicyLink}</Link>
              </Col>
            </Row>
          </Container>
        </copyright>
      </footer>
    </>
  )
}

export default Footer