import React from 'react';
import Form from "./Form"

const ContentfulTemplateContactUsBar = ({ data: { entryTitle, contactIconImage, faxIconImage, emailIconImage,emailAddressLabel ,ctaButtonLabel, faxNumberLabel, contactNumberLabel  }, config}) => {
   
    let { contactNumber, emailAddress, faxNumber } = config

    return (
        <>
            <div className="col-md-6 col-lg-3 col-sm-12 col-xs-12">
                <div className="mt-3 mb-3">
                    <div className="contact-info-icon">
                        <img alt={contactIconImage.description} src={contactIconImage.file.url} />
                    </div>
                    <div className="contact-info">
                        <p><a href={"tel:+44" + contactNumber.substring(1).replace(/\s/g,'')}>{contactNumber}</a></p>
                        <p>{contactNumberLabel}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-12 col-xs-12">
                <div className="mt-3 mb-3">
                    <div className="contact-info-icon">
                        <img alt={faxIconImage.description} src={faxIconImage.file.url} />
                    </div>
                    <div className="contact-info">
                        <p>{faxNumber}</p>
                        <p>{faxNumberLabel}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 col-sm-12 col-xs-12">
                <div className="mt-3 mb-3">
                    <div className="contact-info-icon">
                        <img alt={emailIconImage.description} src={emailIconImage.file.url} />
                    </div>
                    <div className="contact-info">
                        <p><a href={"mailto:" + emailAddress} target="_top">{emailAddress}</a></p>
                        <p>{emailAddressLabel}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-2 col-sm-12 col-xs-12">
                <div className="mt-3 mb-3">
                    <div className="contact-bar-btn">
                        <Form buttonName={ctaButtonLabel} siteConfig={config}/>
                    </div>
                </div>
            </div>
        </>
    );
}


export default ContentfulTemplateContactUsBar