import React from "react"
import { Link } from "gatsby"
import { Row, Col, Button, Container } from "react-bootstrap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const ContentfulTemplateTile = ({ data: { entryTitle, shortBrief, body, image, readMoreUrl, imagePosition, gridClasses, cssClasses}, index, config}) => {

    let buttonTitlePage = config.buttonTitlePage

    const options = {
        renderText: text => text.split('<br>').flatMap((text, i) => [i > 0 && <br />, text])
    }

    return (
        <>    
            <div className={gridClasses}>
            {imagePosition ? (
                <Row className={cssClasses}>
                    <Col lg={6} md={6} sm={12} className="item-first">
                        <img alt={image.description} src={image.file.url} />
                    </Col>
                    <Col lg={6} md={6} sm={12} className="item-second">
                        <div className="Tile-data">
                        {body == null ? ( 
                            <div className >
                            <h4>{entryTitle}</h4>
                            <p>{shortBrief}</p>
                            </div>
                        ) : (
                            <div>
                            {documentToReactComponents(body.json, options)}
                            </div>
                        )}
                            {/* {readMoreUrl !== null && <Button>Read more</Button>} */}
                            {readMoreUrl !== null && <Link className="mobile-hide btn btn-primary" to={readMoreUrl}>{ buttonTitlePage }</Link> }
                        </div>
                    </Col>
                </Row>
            ) : (
                <Row className={cssClasses}>
                <Col lg={6} md={6} sm={12} className="item-second">
                        <div className="Tile-data">
                        {body == null ? ( 
                            <div>
                            <h4>{entryTitle}</h4>
                            <p>{shortBrief}</p>
                            </div>
                        ) : (
                            <div>
                            {documentToReactComponents(body.json, options)}
                            </div>
                        )}
                            {readMoreUrl !== null && <Button>{ buttonTitlePage }</Button>}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className="item-first">
                        <img alt={image.description} src={image.file.url} />
                    </Col>
                </Row>
           )}
            </div>
        </>
    );
}

export default ContentfulTemplateTile