import React from "react"
import { Helmet } from "react-helmet"

const Head = ({ description, title, imageUrl, siteConfig }) => {

    let { contactNumber, emailAddress, faxNumber } = siteConfig
    let defaultTitle = "London Greek Urology";

    return (
        <Helmet title={ title } titleTemplate={`${defaultTitle} | %s`}>
            <meta name="description" content={ description } />
            <meta property="og:image" content={ imageUrl }/>
            <meta property="og:title" content={ title ? defaultTitle + " | " + title : defaultTitle } />
            <meta property="og:description" content={ description } />
            <meta name="og:email" content={emailAddress}/>
            <meta name="og:phone_number" content={contactNumber}/>
            <meta name="og:fax_number" content={faxNumber}/>
        </Helmet>
    )
}

export default Head