import React, { useState } from 'react'
import { Tab, Row, Nav } from 'react-bootstrap'
import * as Components from "./AllComponents";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
const ContentfulTemplateGroup = ({ data: { entryTitle, tabs, type, order, gridClasses }, config }) => {
    const [expandList, expandParms] = useState([]);
    
    const onChange = event => {
        if (typeof window !== 'undefined') {
            const Plyr = require('plyr');
            Array.from(document.querySelectorAll('.plyr--video')).map((p)=>{
                const player =new Plyr(p)
                player.pause();
            });
        }
    };
    const expandAll = event => {
        if (typeof window !== 'undefined') {
            var ele= document.querySelectorAll(".accordion__panel");
            
            for(let i in ele){
                try {
                    ele[i].removeAttribute("hidden");
                }
                catch(err) {
                    console.log(" errr",err)
                }
            }
            var eleBtn= document.querySelectorAll(".accordion__button");
            
            for(let i in eleBtn){
                try {
                    eleBtn[i].setAttribute("aria-expanded", true);
                }
                catch(err) {
                    console.log(" errr",err)
                }
            }
        }
        
    };
    const collapseAll = event => {
        if (typeof window !== 'undefined') {

            var ele= document.querySelectorAll(".accordion__panel");
            
            for(let i in ele){
                try {
                    ele[i].setAttribute("hidden", true);
                    ele[i].setAttribute("aria-expanded", false);
                }
                catch(err) {
                    console.log(" errr",err)
                }
            }

            var eleBtn= document.querySelectorAll(".accordion__button");
            for(let i in eleBtn){
                try {
                    eleBtn[i].setAttribute("aria-expanded", false);
                }
                catch(err) {
                    console.log(" errr",err)
                }
            }
        }
    };

    let { pageFaqExpandAll, pageFaqCollapseAll } = config
    
    return (
        <>
            {(() => {
                if (type === "Tabs") {
                 
                    
                    return (
                        <>
                            <Tab.Container id="left-tabs-example" defaultActiveKey={tabs[0].entryTitle} onSelect={onChange}>

                                <div className={gridClasses}>
                                    <Nav>
                                        {
                                            tabs.map((tab) => {
                                                return (
                                                    <>
                                                     
                                                        <Nav.Item>
                                                            <Nav.Link eventKey={tab.entryTitle}>{tab.entryTitle}</Nav.Link>
                                                        </Nav.Item>
                                                        
                                                    </>
                                                )
                                            })
                                        }
                                    </Nav>
                                </div>
                                <div className={gridClasses}>
                                    <Tab.Content >
                                        {
                                            tabs.map((tab, i) => {
                                                let entryTitle = tab.entryTitle;
                                                return (
                                                    <>
                                                        <Tab.Pane eventKey={entryTitle}  >
                                                            <Row>
                                                                {tab.templates.map((template, i) => {
                                                                    let Component = Components[template.__typename];
                                                                    
                                                                    return (
                                                                        <Component  key={template.entryTitle} data={template} index={i}  onNameChange={onChange}/>
                                                                    )
                                                                })
                                                                }
                                                            </Row>
                                                        </Tab.Pane>
                                                    </>
                                                )
                                            })
                                        }
                                    </Tab.Content>
                                </div>

                            </Tab.Container>
                        </>
                    )
                }
                else if (type === "Accordion") {
                    
                    return (
                        <>
                            <div className={gridClasses} >
                            
                            {(() => {
                                if (order === 1) {
                                    return (
                                        <div className="mb-3"><a href="#" onClick={expandAll}>{ pageFaqExpandAll }</a> | <a href="#" onClick={collapseAll}>{ pageFaqCollapseAll }</a></div>
                                    )
                                }
                            })()}
                                <Accordion allowMultipleExpanded="true" allowZeroExpanded="true" >
                                    
                                    {
                                        tabs.map((tab, i) => {
                                            return (
                                                <>
                                                <AccordionItem >
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                        {tab.entryTitle}
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel >
                                                    {tab.templates.map((template, i) => {
                                                            let Component = Components[template.__typename];
                                                            return (
                                                                <Component key={template.entryTitle} data={template} index={i} />
                                                            )
                                                        })
                                                    }
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                </>
                                            )
                                        })
                                    }
                                   
                                </Accordion>
                            </div>
                        </>
                    )
                }
            })()}
        </>
    )

}

export default ContentfulTemplateGroup