import React, { Component } from "react"
import Header from "./SiteHeader"
// import Navbar from "./Navbar"
import Footer from "./SiteFooter"
import "./SiteLayout.module.scss"
import "./Navbar.module.scss"
import { Nav, Navbar, Container, Row, Col, NavDropdown, Form, FormControl } from 'react-bootstrap';
import _ from "lodash"
import { Link } from "gatsby"

class SiteLayout extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      expanded: false
    }
  }

  handleClick = (e) => {
    console.log(e.target.parentElement)

    let target = e.target.parentElement.dataset.eventkey
    // console.log(target.eventkey)
    let ids = ['mainNav', 'mainNavToggle']
    let eventKeys = ['mainNav']
    if(!eventKeys.includes(target)) {
      this.setState({expanded: false})
    }
  }

  toggleNav = (e) => {
      console.log(e)
      this.setState({expanded: e})
  }

  render() {
    let {allNav, localeNav, currNav,siteConfig, children} = this.props;
    var currentLanguage = localeNav.edges[0].node.node_locale;
    const navDataSet = _.groupBy(localeNav.edges, (navlink) => {
        if (navlink.node.navParent) {
            return navlink.node.navParent
        }
        else {
            return navlink.node.navTitle
        }
    })
    currentLanguage = currentLanguage === "en" ? "el" : "en";
    var UrlSlug = currNav.edges[0].node.slug;
    return (
      <div onClick={(e) => this.handleClick(e)}>
         <div className="fixed-top">
          <Header allNav={allNav} localeNav={localeNav} siteConfig={siteConfig} />
          {/* <Navbar allNav={allNav} localeNav={localeNav} currNav={currNav}/> */}
          <Navbar expand="md" expanded={this.state.expanded} onToggle={(e) => this.toggleNav(e)}>
              <Container>
                  <Row>
                      <Col>
                          <Navbar.Toggle aria-controls="basic-navbar-nav" data-eventKey={`mainNav`} id="mainNavToggle" />
                          <Navbar.Collapse id="basic-navbar-nav">
                              <Nav className="mr-auto" as="ul" data-eventKey={`mainNav`} id="mainNav">
                                  {
                                      localeNav.edges.map(({ node: { navTitle, navParent, navOrder, node_locale, pageType, pageTitle, contentful_id, slug, navShow } }) => {
                                          if(navShow) {
                                              if (!navParent) {
                                                  if (navDataSet[navTitle].length > 1) {
                                                      return (
                                                          <NavDropdown data-eventKey={`mainNav`} key={navTitle} title={navTitle} id={`basic-nav`}>
                                                              {
                                                                  (() => {
                                                                      let navDropdownOptions = _.orderBy(navDataSet[navTitle], ['node.navDropdownOrder'], ['ASC'])
                                                                      return navDropdownOptions.map(({ node: { navTitle: dropTitle, navParent: dropParent, pageType: dropPageType, node_locale: dropNode_locale, pageTitle: dropPageTitle, contentful_id: dropCID, slug } }) => {
                                                                          let url = `/${dropNode_locale}/${slug}`
                                                                          if (dropParent) {
                                                                              return (
                                                                                  <NavDropdown.Item as="li" key={dropTitle}><Link to={url}>{dropTitle}</Link></NavDropdown.Item>
                                                                              )
                                                                          }
                                                                      })
                                                                  })()

                                                              }
                                                          </NavDropdown>
                                                      )
                                                  }
                                                  else {
                                                      let url = `/${node_locale}/${slug}`
                                                      return (
                                                          <Nav.Link id={navTitle} as="li" key={navTitle}><Link to={url}>{navTitle}</Link></Nav.Link>
                                                      )
                                                  }
                                              }
                                          }

                                      })
                                  }
                                  <Nav.Link as="li" key="switch">
                              {currentLanguage ==  'en'? (
                                  <Link  to={'/en/'+UrlSlug}><img  className="flag" src="https://images.ctfassets.net/d8pr1q08pujm/1hVTm2KjmYJHz3gMosjRNt/530b228088a035b80338b99655d95712/United.svg"/></Link>
                              ) : (
                                  <Link to={'/el/'+UrlSlug}><img className="flag" src="https://images.ctfassets.net/d8pr1q08pujm/47MA47rTf0zKUqhU3Q8cVi/a24f061ccb7bdf4092cc1a57f4b8a28b/GreekC_flag.png"/></Link>
                              )}
                              </Nav.Link>
                              </Nav>
                          </Navbar.Collapse>
                      </Col>
                  </Row>
                  <Row>
                      <Col className="float-right">
                          <div className="flag-box mobile-hide mr-sm-0 ml-sm-0 pl-3" key="switch">
                          {/* <OverlayTrigger
                              key="bottom"
                              placement="bottom"
                              overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                      Greek translation is coming soon.
                                  </Tooltip>
                              }
                              > */}
                              {currentLanguage ==  'en'? (
                                  <Link  to={'en/'+UrlSlug}><img  className="flag" src="https://images.ctfassets.net/d8pr1q08pujm/1hVTm2KjmYJHz3gMosjRNt/530b228088a035b80338b99655d95712/United.svg"/></Link>
                              ) : (
                                  <Link to={'el/'+UrlSlug}><img className="flag" src="https://images.ctfassets.net/d8pr1q08pujm/47MA47rTf0zKUqhU3Q8cVi/a24f061ccb7bdf4092cc1a57f4b8a28b/GreekC_flag.png"/></Link>
                              )}

                              
                              {/* <Button variant="secondary">Tooltip on bottom</Button> */}
                              {/* </OverlayTrigger> */}
                          
                          </div>
                          <div className="search-box mobile-hide desktop-hide">
                          <Form>
                              <img src="https://images.ctfassets.net/d8pr1q08pujm/1l4j7DpoiyCxm9Nqwk0C93/4310dc388029ba83b8731ab8793bafc0/search-light.svg"/>
                              <FormControl type="text" placeholder="Search here..." className="mobile-hides mr-sm-2" />
                              {/* <Button className="mr-sm-2" variant="outline-success">Search</Button> */}
                          </Form>
                          </div>
                      </Col>
                  </Row>
              </Container>
          </Navbar>
        </div>
        <main className={currentLanguage}>
          {children}
        </main>
        <Footer allNav={allNav} localeNav={localeNav} siteConfig={siteConfig} />
      </div>
    )
  }
}

// const SiteLayout = ({allNav, localeNav, currNav,siteConfig, children}) => {
  
//   var currentLanguage = localeNav.edges[0].node.node_locale;
  
//   return (
//     <>
//        <div className="fixed-top">
//       <Header allNav={allNav} localeNav={localeNav} siteConfig={siteConfig} />
//       <Navbar allNav={allNav} localeNav={localeNav} currNav={currNav} />
//       </div>
//       <main className={currentLanguage}>
//       {children}
//       </main>
//       <Footer allNav={allNav} localeNav={localeNav} siteConfig={siteConfig} />
//     </>
//   )
// }
export default SiteLayout
