import React from 'react';
import GoogleMapReact from 'google-map-react';


const ContentfulTemplateMap = ({ data, data: { entryTitle, latLong, latLong2, latLong3, gridClasses } }) => {
  const AnyReactComponentOne = ({ text }) => <div className="map-pointer"><a target="_blank" href={"https://www.google.com/maps/search/?api=1&query="+latLong.lat+","+latLong.lon+""}><div className="map-lable">{ text }</div><img src="https://images.ctfassets.net/d8pr1q08pujm/23I6lDilkDOPOqS8Wh3b4c/7ef34aad9a8e4f96b662d9b83e9093b5/maps-and-flags.svg" /></a></div>;
  const AnyReactComponentTwo = ({ text }) => <div className="map-pointer"><a target="_blank" href={"https://www.google.com/maps/search/?api=1&query="+latLong2.lat+","+latLong2.lon+""}><div className="map-lable">{ text }</div><img src="https://images.ctfassets.net/d8pr1q08pujm/23I6lDilkDOPOqS8Wh3b4c/7ef34aad9a8e4f96b662d9b83e9093b5/maps-and-flags.svg" /></a></div>;
  const AnyReactComponentThree = ({ text }) => <div className="map-pointer"><a target="_blank" href={"https://www.google.com/maps/search/?api=1&query="+latLong3.lat+","+latLong3.lon+""}><div className="map-lable">{ text }</div><img src="https://images.ctfassets.net/d8pr1q08pujm/23I6lDilkDOPOqS8Wh3b4c/7ef34aad9a8e4f96b662d9b83e9093b5/maps-and-flags.svg" /></a></div>;

  var defaultProps = {
    center: {
      lat: 51.613128,
      lng: -0.1157127,
    },
    zoom: 12
  };
  
  return (
    <>
      <div className={gridClasses} style={{ height: '550px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyB-nhPSVGsDe6InTeDu4_tmTxFlxxjpCJw' }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponentOne
            lat={latLong.lat}
            lng={latLong.lon}
            text="BMI The Kings Oak Hospital"
          />
            <AnyReactComponentTwo
            lat={latLong2.lat}
            lng={latLong2.lon}
            text="Highgate Private Hospital"
          />
           <AnyReactComponentThree
            lat={latLong3.lat}
            lng={latLong3.lon}
            text="BMI The Cavell Hospital"
          />
        </GoogleMapReact>
      </div>
    </>
  );
}


export default ContentfulTemplateMap