import React from 'react'
import { Button } from 'react-bootstrap';
import 'react-notifications/lib/notifications.css';

const CTA = (props) => {
    const handleOpenModal = event => {
        props.handleOpenModal();
      };
  return (
    <>
        {(() => {
          if (props.isFooter === undefined && props.buttonName !== undefined) {
            return (
            <Button className={props.className} onClick={handleOpenModal}>{props.buttonName}</Button>
            )
        }
      })()}
    
      
      <div className="mob-sticky desktop-hide">
         {(() => {
          if (props.tel) {
              return (
              <>
                <Button><a href={ "tel:+44" + props.tel.substring(1).replace(/\s/g,'')} >{props.tel}</a></Button>
                <Button onClick={handleOpenModal}>Book online</Button>
              </>
              )
          }
        })()}
           
        </div>
    </>
  );
}
export default CTA