import React, { Component } from 'react';
import Form  from "./Form"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
// import plyr from 'plyr';
// import styles from 'plyr/dist/plyr.css';

// const ContentfulTemplateVideo = ({data: {entryTitle, title, body: {json}, videoUrl}}) => {


//     return (

//         <div  style={{
//                         width: `50% `,
//                         margin: `0 auto`,
//                       }} 
//                       >
//                         <div
//                         id="plyr-player"
//                         data-plyr-provider="youtube"
//                         data-plyr-embed-id="bTqVqk7FSmY" />
//                         <h2>What is Lorem Ipsum?</h2>
//                         <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
//                   </div>
//     );
// }

// export default ContentfulTemplateVideo
import styles from 'plyr/dist/plyr.css';
class ContentfulTemplateVideo extends Component {

    constuctor(props) {
        this.props = props;
    }

    componentDidMount() {
        const plyr = require("plyr");
        const options = {};
        this.player = plyr.setup('#plyr-player', options);
    }
    componentWillUnmount() {
        // if (this.player !== null && this.player.length > 0) {
        //     for (const playerEl of this.player) {
        //         playerEl.destroy();
        //     }
        // }
    }

    render() {
        return (
            <div className={this.props.data.gridClasses} >
                    <div className={this.props.data.cssClasses} >
                    {/* {this.props.data.heading ? 
                            <h1>{this.props.data.heading}</h1>
                    : null } */}
                        {(() => {
                            const options = {
                                renderText: text => text.split('<br>').flatMap((text, i) => [i > 0 && <br />, text])
                            }
                            if (this.props.data.body !== null) {
                                return (
                                    <>{documentToReactComponents(this.props.data.body.json, options)}</>
                                )                                
                            }
                        })()}

                        <div>
                            <div ref="vidRef"
                                id="plyr-player"
                             
                                data-plyr-provider="youtube"
                                data-plyr-embed-id={this.props.data.videoUrl} />
                        </div>
                        
                        {this.props.data.showCtaButton ? 
                            <Form config={this.props.config} />
                        : null }
                            
                    </div>
            </div>
        );
    }
}

// ContentfulTemplateVideo.propTypes = {
//   data: PropTypes.object.isRequired
// }

export default ContentfulTemplateVideo

