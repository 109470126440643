import React from 'react';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Row, Col } from "react-bootstrap"

const ContentfulTemplateBgImageBody = ({ data: { entryTitle, heroImage, column1, column2, templateType, templateStyle, gridClasses, cssClasses, cssId } }) => {

    const options = {
        renderText: text => text.split('<br>').flatMap((text, i) => [i > 0 && <br />, text])
    }

    return (
        <div className={gridClasses}>
            <div  id={cssId} className="testimonial">
                <Row>
                    <Col>
                        <img alt={heroImage.description} src={heroImage.file.url} />
                        <h5>{entryTitle}</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {documentToReactComponents(column1.json, options)}
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ContentfulTemplateBgImageBody



