import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "react-bootstrap";
import SiteHeaderStyle from "./SiteHeader.module.scss"
import Form from "./Form"
import { Link } from "gatsby"

const Header = ({siteConfig}) => {
    
    return (
        <>
            <header>

            <script type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: `
                "@context": "http://schema.org/",
                "@type": "Physician",
                "name": "Mr Paul Erotocritou",
                "medicalSpecialty": "Urologist",
                "url": "https://www.londongreekurology.com/en/home",
                "image": "https://cdn.myhealthspecialist.com/assets/images/uploads/paul-erotocritou.png?112757919",
                "telephone": "020 3875 7995",
                "priceRange": "$",
                "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "International House",
                    "addressRegion": "Hampshire",
                    "postalCode": "SO18 2RZ",
                    "streetAddress": "George Curl Way, Eastleigh"
                }`,
            }}
            />
                <Container>
                    <Row>
                        <Col className="col-md-4">
                            <Link to={siteConfig.homePageUrl}><img className={SiteHeaderStyle.logoimage} alt={siteConfig.siteLogo.description} src={siteConfig.siteLogo.file.url} alt="LGU Logo" /></Link>
                        </Col>
                        <Col className="col-md-8">
                            <div className="text-right pt-3">
                                <div className="contact-number">
                                    <headerimage>
                                        <img className="mobile-hide" alt="Telephone icon" src="https://images.ctfassets.net/d8pr1q08pujm/2KQj1AIuM6yhlx5syCKVXA/cc8234ab32ece319a982fd2135fa49d7/phone-alt-solid.svg" />
                                    </headerimage>
                                    <headerphone>
                                        <a className="mobile-hide" href={"tel:+44" + siteConfig.contactNumber.substring(1).replace(/\s/g, '')}>{siteConfig.contactNumber}</a>
                                    </headerphone>
                                </div>
                                <Form config={siteConfig} classData="mobile-hide" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
        </>
    )

}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
