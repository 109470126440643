import React from "react"
import * as Components from "./AllComponents"
import { Container, Row, Col, Tabs } from "react-bootstrap"


const ContentfulSection = ({ data: { entryTitle, heading, bgImage, subHeading, templates, fullWidth, sectionClass, containerClass, multiComponent }, config }) => {

return (
    <section className={sectionClass} style={bgImage !== null ? {  backgroundImage: `url(${bgImage.file.url})`,backgroundRepeat: `no-repeat`,backgroundSize: `cover` } : {backgroundImage : `none`}}>
        <div>
            <Container className={containerClass} fluid={fullWidth}>     
                <Row>{ templates.map((template, i) => {
                    let Component = Components[template.__typename];
                    return (
                        <Component key={template.entryTitle} data={template} index={i} config={config} />
                    );
                    
                }) }</Row>
            </Container>
        </div>
    </section>
)
}

export default ContentfulSection