import React, { Component } from "react"
import Layout from "../components/SiteLayout"
import ContentfulSection from "../components/ContentfulSection"
import Head from "../components/SiteMeta"
import { graphql } from "gatsby"
export const data = graphql`
    query(
        $pageTitle: String,
  			$node_locale: String
) {contentfulPage(pageTitle: { eq: $pageTitle }, node_locale: {eq: $node_locale}) { ...page sections {
              ...section templates { __typename  ...video ...map ...bar ...carousel ...tile
              ...body ...extendedParagraph ...group
              }} siteConfig { ...config} } localeNav: allContentfulPage (
                filter: {navTitle: {ne: null}, node_locale: {eq: $node_locale}}
                sort: { fields: navOrder, order:ASC}
            ) {
                edges { node {...nav }}
            }
						allNav: allContentfulPage (
                filter: {navTitle: {ne: null}, node_locale: {eq: "en"}}
                sort: { fields: navOrder, order:ASC}
            ) {
                edges { node {...nav }}
            }
            currNav: allContentfulPage (
              filter: {pageTitle: {eq: $pageTitle}, node_locale: {eq: $node_locale}}
          ) {
              edges { node {...page }}
          }}
  
    fragment page on ContentfulPage {
       entryTitle pageTitle pageDescription pageImage{file{url}} pageImage{description} slug
    }
    fragment section on ContentfulSection {
      entryTitle heading subHeading fullWidth sectionClass containerClass multiComponent bgImage {file{url}} bgImage {description}
    }
    fragment carousel on ContentfulTemplateCarousel {
       entryTitle slides {...body} gridClasses
    }
    fragment body on ContentfulTemplateBgImageBody {
      entryTitle heroImage {file{url}} heroImage {description} column1 {json} column2 {json} templateType templateStyle gridClasses groupTitle floatType cssClasses cssId
    }
    fragment tile on ContentfulTemplateTile {
      entryTitle shortBrief body {json} readMoreUrl image {file {url}} image {description} imagePosition gridClasses cssClasses
    }
    fragment extendedParagraph on ContentfulTemplateExtendedParagraph {
      entryTitle body {json} author title photo {file {url}} photo {description} pinned gridClasses showCtaButton
    }
    fragment tab on ContentfulTemplateTab {
      entryTitle heading templates { __typename ...extendedParagraph ...video} gridClasses
    }
    fragment video on ContentfulTemplateVideo {
      entryTitle title videoUrl showCtaButton body {json} cssClasses gridClasses 
    }
    fragment group on ContentfulTemplateGroup {
      entryTitle gridClasses order tabs {
        ...tab
      } type
    }
    fragment map on ContentfulTemplateMap {
      entryTitle latLong {lat, lon} latLong2 {lat, lon} latLong3 {lat, lon} gridClasses
    }
    fragment bar on ContentfulTemplateContactUsBar {
      entryTitle contactNumberLabel contactIconImage {file {url}} contactIconImage {description} faxNumberLabel faxIconImage {file {url}} faxIconImage {description} emailAddressLabel ctaButtonLabel emailIconImage {file {url}} emailIconImage {description}
    }
    fragment nav on ContentfulPage {
      entryTitle contentful_id pageType pageTitle navTitle navParent navOrder navDropdownOrder node_locale navShow slug
    }
    fragment config on ContentfulSiteConfig {
      siteLogo {file {url}} contactNumber faxNumber emailAddress homePageUrl privacyPolicyPageUrl testimonialsPageUrl footerHeader {json} footerAddress {json} footerImage1 {file {url}} footerImage2 {file {url}} footerAppointmentsHeader footerAppointmentsLabel1 footerAppointmentsLabel2 footerAppointmentsLabel3 footerCopyright footerPrivacyPolicyLink buttonTitleCta buttonTitlePage bookingFormHeading bookingFormField1Placeholder bookingFormField1ValidationError bookingFormField2Placeholder bookingFormField2ValidationError bookingFormField3Placeholder bookingFormField3ValidationError bookingFormField4Placeholder bookingFormField4ValidationError bookingFormSubmitButton pageFaqExpandAll pageFaqCollapseAll
    }
`


const Page = ({ data: { allNav, localeNav, currNav, contentfulPage: { pageTitle, pageDescription, pageImage: { file: { url } }, sections, siteConfig} }, pageContext: { node_locale } }) => {
  return (
    <>
      <div></div>
      <Layout allNav={allNav} localeNav={localeNav} currNav={currNav} siteConfig={siteConfig} >
        <Head title={pageTitle} description={pageDescription} imageUrl={url}  siteConfig={siteConfig} />
        {
          sections.map((section) => {
            return (
              <ContentfulSection key={section.entryTitle} data={section} config={siteConfig} />
            )
          })
        }
      </Layout>
    </>
  )
}

export default Page