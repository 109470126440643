import React, { useState } from 'react'
import Modal from 'react-modal';
import axios from 'axios';
import { Button, Col, Form } from 'react-bootstrap';
import 'react-notifications/lib/notifications.css';
import swal from 'sweetalert';
import CTA from './CTA';

const customStyles = {
  content: {
    top: '60%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    // marginRight           : '-50%',
    padding: '40px 25px',
    transform: 'translate(-50%, -50%)',
  }
};
const GatsbyForm = (props) => {

  const [modalOpen, setModalOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  // let config = props.config;
  let buttonTitleCta = props.config !== undefined ? props.config.buttonTitleCta : props.buttonName;
  let { bookingFormHeading, bookingFormField1Placeholder, bookingFormField1ValidationError, bookingFormField2Placeholder, bookingFormField2ValidationError, bookingFormField3Placeholder, bookingFormField3ValidationError, bookingFormField4Placeholder, bookingFormField4ValidationError, bookingFormSubmitButton } = props.config || props.siteConfig


  const handleCloseModal = event => {
    setModalOpen(false);
  };
  const handleOpenModal = event => {

    setModalOpen(true);
  };

  const handleSubmitForm = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

    } else {
      event.preventDefault();
      const data = {
        "name": event.target[0].value,
        "phone": event.target[1].value,
        "email": event.target[2].value,
        "message": event.target[3].value
      }
      axios.post(process.env.GATSBY_FORM_API_GATEWAY_URL, data).then(data => {
        setModalOpen(false);
        swal({
          title: "Thank you for your enquiry!",
          text: "We will get back to you shortly.",
          icon: "success",
          button: {
            text: "Close",
            closeModal: true,
          },
        })
      }).catch(err => {
        swal({
          title: "Sorry!",
          text: "Unfortunately, there was an issue. Please try again or contact us!",
          icon: "error",
          button: {
            text: "Close",
            closeModal: true,
          },
        })
      })

    }
    setValidated(true);
  };



  return (
    <>
      <span className="desktop-hide">
        {(() => {
          if (props.config !== undefined) {
            return (
              <>
                <CTA handleOpenModal={handleOpenModal} tel={props.config.contactNumber} />
              </>
            )
          }
        })()}
      </span>

      <CTA className={props ? props.classData : null} isFooter={props.isFooter} buttonName={buttonTitleCta} handleOpenModal={handleOpenModal} />
      <Modal style={customStyles} ariaHideApp={false} isOpen={modalOpen} onRequestClose={handleCloseModal} contentLabel="Minimal Modal Example" shouldReturnFocusAfterClose={false}>
        <div className="contact-form">
          <span className="form-close" onClick={handleCloseModal}><img src="https://images.ctfassets.net/d8pr1q08pujm/GWa4MlzgsFz3bVDBL8DQ5/f1a0a70627167b32a8cc2babb1151351/times-square-light.svg" /></span>
          <h3 className="text-center">{bookingFormHeading}</h3>
          <div className="sweet-loading">
            <Form noValidate validated={validated} onSubmit={handleSubmitForm}>
              <Form.Row>
                <Col>
                  <Form.Group controlId="name" controlId="validationCustom01">
                    <Form.Control placeholder={bookingFormField1Placeholder} required />
                    <Form.Control.Feedback type="invalid">
                      {bookingFormField1ValidationError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group controlId="phone" controlId="validationCustom02">
                    <Form.Control placeholder={bookingFormField2Placeholder} required />
                    <Form.Control.Feedback type="invalid">
                      {bookingFormField2ValidationError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group controlId="email" controlId="validationCustom03">
                    <Form.Control placeholder={bookingFormField3Placeholder} type="email" required />
                    <Form.Control.Feedback type="invalid">
                      {bookingFormField3ValidationError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group controlId="message" controlId="validationCustom04">
                    <Form.Control style={{ height: '100px' }} as="textarea" rows="3" placeholder={bookingFormField4Placeholder} required />
                    <Form.Control.Feedback type="invalid">
                      {bookingFormField4ValidationError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
              <div className="text-center">
                <Button variant="primary" type="submit">
                  {bookingFormSubmitButton}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default GatsbyForm